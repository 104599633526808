<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">PRC ID</v-subheader>
                </v-row>
                <v-row align="center">
                    <v-text-field
                        ref="prc_id_no"
                        pattern="\d*" 
                        type="tel"
                        counter="7"
                        v-model="prc_id_no"
                        :rules="[rules.required, rules.prc_id_no]"
                        color="csb_yellow"
                        label="PRC ID No."
                        @keydown="isAmount('prc_id_no')"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center">

                    <v-dialog
                        ref="dialog_prc_registration_date"
                        v-model="modal_prc_registration_date"
                        :return-value.sync="prc_registration_date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                v-model="prc_registration_date"
                                label="PRC ID Registration Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_prc_registration_date"
                            v-model="prc_registration_date"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1930-01-01"
                            @change="$refs.dialog_prc_registration_date.save(prc_registration_date)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_prc_registration_date = false, $refs.picker_prc_registration_date.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center">

                    <v-dialog
                        ref="dialog_valid_until"
                        v-model="modal_valid_until"
                        :return-value.sync="valid_until"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                :rules="[rules.required]"
                                v-model="valid_until"
                                label="PRC ID Expiration Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_valid_until"
                            v-model="valid_until"
                            :max="max_date_valid_until"
                            :min="new Date().toISOString().substr(0, 10)"
                            @change="$refs.dialog_valid_until.save(valid_until)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_valid_until = false, $refs.picker_valid_until.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Employment Details</v-subheader>
                </v-row>

                <v-row align="center">

                    <v-dialog
                        ref="dialog_date_hired"
                        v-model="modal_date_hired"
                        :return-value.sync="date_hired"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                :rules="[rules.required]"
                                v-model="date_hired"
                                label="Date Hired"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_date_hired"
                            v-model="date_hired"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1930-01-01"
                            @change="$refs.dialog_date_hired.save(date_hired)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_date_hired = false, $refs.picker_date_hired.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center">
                    <v-autocomplete 
                        autocomplete="designation"
                        ref="designation"
                        :rules="[rules.required]"
                        :loading="designation_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="designation" 
                        :items="designation_options" 
                        item-text="display" 
                        item-value="value" 
                        label="Designation">
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="nature_of_appointment" 
                            :rules="[rules.required]"
                            :items="nature_of_appointment_options" 
                            item-text="display" 
                            item-value="value" 
                            label="Nature of Appointment" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px; margin-bottom: 16px;">
                    <v-text-field
                        ref="monthly_salary"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        prefix="₱"
                        v-model="monthly_salary"
                        label="Monthly Salary"
                        pattern="\d*" 
                        type="tel"
                        @keydown="isAmount('monthly_salary')"
                        hide-details="auto">
                        
                    </v-text-field>
                </v-row>

                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Loan Purpose</v-subheader>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="loan_type"
                        ref="loan_type"
                        :rules="[rules.required]"
                        :loading="loan_type_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="loan_type" 
                        :items="loan_type_options" 
                        :search-input.sync="search_loan_type"
                        item-text="display" 
                        item-value="value" 
                        label="Loan Type"
                        @change="loanTypeOnChange()">
                    
                    </v-autocomplete>
                </v-row>
                
                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="loan_purpose"
                        ref="loan_purpose"
                        :rules="[rules.required]"
                        :loading="loan_purpose_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="loan_purpose" 
                        :items="loan_purpose_options" 
                        :search-input.sync="search_loan_purpose"
                        item-text="display" 
                        item-value="value" 
                        label="Loan Purpose"
                        :disabled="disable_loan_purpose">
                    
                    </v-autocomplete>
                </v-row>

                
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Referrer</v-subheader>
                </v-row>
                
                <!-- :rules="[rules.required, rules.employee_number]" -->
                <v-row align="center">
                    <v-text-field
                        ref="employee_number"
                        pattern="\d*" 
                        type="tel"
                        counter="7"
                        v-model="employee_number"
                        color="csb_yellow"
                        label="Employee Number"
                        @keydown="isAmount('employee_number')"
                        required>
                    </v-text-field>
                </v-row>

                <!-- :rules="[rules.required]" -->
                <v-row align="center">
                    <v-text-field
                        ref="first_name"
                        v-model="first_name"
                        color="csb_yellow"
                        label="First Name">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                            ref="middle_name"
                            v-model="middle_name"
                            color="csb_yellow"
                            label="Middle Name">
                    </v-text-field>
                </v-row>
                
                <!-- :rules="[rules.required]" -->
                <v-row align="center">
                    <v-col cols="8" class="pr-2" style="padding: 0px;">
                        <v-text-field
                            ref="last_name"
                            v-model="last_name"
                            color="csb_yellow"
                            label="Last Name">
                        </v-text-field>
                    </v-col>
                    <v-col  cols="4" style="padding: 0px;">
                        <v-text-field
                            ref="suffix"
                            v-model="suffix"
                            color="csb_yellow"
                            label="Suffix">
                        </v-text-field>
                    </v-col>      
                </v-row>
                
              
                <v-row align="center">

                    <v-dialog
                        ref="dialog_birthdate"
                        v-model="modal_birthdate"
                        :return-value.sync="birthdate"
                        persistent
                        width="290px"
                    >
                        <!-- :rules="[rules.required]" -->
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                v-model="birthdate"
                                label="Birthdate"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_birthdate"
                            v-model="birthdate"
                            :max="max_date_birthdate"
                            min="1930-01-01"
                            @change="$refs.dialog_birthdate.save(birthdate)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_birthdate = false, $refs.picker_birthdate.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <!-- :rules="[rules.required, rules.mobile_no_length, rules.mobile_no_prefix]" -->
                <v-row class="mb-4" align="center" style="margin-top: -6px;">
                    <v-text-field
                        ref="mobile_no"
                        prefix="+63"
                        pattern="\d*" 
                        type="tel"
                        counter="10"
                        v-model="mobile_no"
                        color="csb_yellow"
                        label="Mobile No"
                        @keydown="isAmount('mobile_no')"
                        required>
                    </v-text-field>
                </v-row>
            </v-form>
        </v-container>
      
        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getLookupValues } from '@/api/profile'
import { mapGetters } from 'vuex'

const validPrefixes = [
    '905','906','915','916','917','926','927','935','936','945','953','954','955','956','963','964','965','966','967', 
    '975','976','977','994','995','996','997','817','813','900','907','908','909','910','911','912','913','914','918','919','920','921','928',
    '929','930','938','939','940','946','947','948','949','950','951','957','958','959','960','961','968','969','970','971','980','981','982','985',
    '989','992','998','999','922','923','924','925','931','932','933','934','941','942','943','944','951','952','962','971','972',

]

const mustBeValidPrefix = (value) => {
    var prefix = value.substring(0, 3);
    var res = validPrefixes.indexOf(prefix) > -1
    return res
}
   

export default {
    computed: {

        ...mapGetters([
            'others',
        ]),
    },

    beforeRouteLeave(to, from, next) {

        if(to.name == 'Calculator' || to.name == 'Profile'){
            var othersFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/othersFormIsValid', othersFormIsValid)
            next()
        } else {

            if(this.$refs.form.validate()){
                var data = {
                    prc_id_no: this.prc_id_no,
                    designation: this.designation,
                    designation_options: this.designation_options,
                    designation_loading: this.designation_loading,
                    monthly_salary: this.monthly_salary,
                    nature_of_appointment: this.nature_of_appointment,
                    nature_of_appointment_options: this.nature_of_appointment_options,
                    prc_registration_date: this.prc_registration_date,
                    modal_prc_registration_date: this.modal_prc_registration_date,
                    cancel_prc_registration_date: this.cancel_prc_registration_date,
                    mobile_no: this.mobile_no,
                    employee_number: this.employee_number,
                    first_name: this.first_name,
                    middle_name: this.middle_name,
                    last_name: this.last_name,
                    suffix: this.suffix,
                    birthdate: this.birthdate,
                    modal_birthdate: this.modal_birthdate,
                    cancel_birthdate: this.cancel_birthdate,
                    loan_type: this.loan_type,
                    loan_type_options: this.loan_type_options,
                    loan_type_loading: this.loan_type_loading,
                    search_loan_type: this.search_loan_type,
                    loan_purpose: this.loan_purpose,
                    loan_purpose_options: this.loan_purpose_options,
                    loan_purpose_loading: this.loan_purpose_loading,
                    disable_loan_purpose: this.disable_loan_purpose,
                    search_loan_purpose: this.search_loan_purpose,
                    valid_until: this.valid_until,
                    modal_valid_until: this.modal_valid_until,
                    date_hired: this.date_hired,
                    modal_date_hired: this.modal_date_hired,
                }
                
                this.$store.dispatch('user/others', data)
                this.$store.dispatch('user/othersFormIsValid', true)

                next();

            } 
        } 
      
    },
  
    data: function() {
        return {
            prc_id_no: '',
            
            monthly_salary: '',

            nature_of_appointment: '',
            nature_of_appointment_options: [
                {display: 'Regular' ,value: 'Regular'},
                {display: 'Contractual' ,value: 'Contractual'}
            ],
         
            prc_registration_date: null,
            modal_prc_registration_date: false,
            cancel_prc_registration_date: false,

            mobile_no: '',
            employee_number: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',

            birthdate: null,
            modal_birthdate: false,
            cancel_birthdate: false,

            loan_type: null,
            loan_type_options: [],
            loan_type_loading: true,
            search_loan_type: null,

            loan_purpose: null,
            loan_purpose_options: [],
            loan_purpose_loading: false,
            disable_loan_purpose: true,
            search_loan_purpose: null,

            designation: null,
            designation_options: [],
            designation_loading: false,

            valid_until: null,
            modal_valid_until: false,

            date_hired: null,
            modal_date_hired: false,

            //rules
            rules: {
                required: v => !!v || 'Required.',
                employee_number:  v => v.length == 7 || 'Employee Number must be 7 digits',
                prc_id_no:  v => v.length == 7 || 'PRC ID No. must be 7 digits',
                mobile_no_length:  v => v.length == 10 || 'Mobile no must be 10 digits',
                mobile_no_prefix:  v => mustBeValidPrefix(v) || 'Invalid mobile no prefix',
              
            },
        }
    },

    watch: {
        modal_prc_registration_date (val) {
            val && setTimeout(() => (this.$refs.picker_prc_registration_date.activePicker = 'YEAR'))
        },

        modal_valid_until (val) {
            val && setTimeout(() => (this.$refs.picker_valid_until.activePicker = 'YEAR'))
        },

        modal_date_hired (val) {
            val && setTimeout(() => (this.$refs.picker_date_hired.activePicker = 'YEAR'))
        },
        
        modal_birthdate (val) {
            val && setTimeout(() => (this.$refs.picker_birthdate.activePicker = 'YEAR'))
        },

    },
  
    methods:{
        handleBack(){

            this.$router.push({ path: '/profile/index' })

        },
        handleNext(){

            this.$router.push({ path: '/attachment/index' })
        },

        fetchLookupValues(value, id=''){
            return getLookupValues(value, id).then(res => {
                return res.data.data
                
            })
        },

        loanTypeOnChange(){ 
            if(this.loan_type) {
                this.disable_loan_purpose = false
                this.loan_purpose = null
                this.$refs.loan_purpose.reset()

                this.loan_purpose_loading = true

                if(this.loan_type == 'Agriculture'){
                    
                    this.fetchLookupValues('maria-agriculture-loan-purpose').then(res => {
                        this.loan_purpose_options = res
                    })
                } else {
                   
                    this.fetchLookupValues('maria-consumption-loan-purpose').then(res => {
                        this.loan_purpose_options = res
                    })
                }

                this.loan_purpose_loading = false
                

            } else {

                this.disable_loan_purpose = true
                this.loan_purpose = null
                this.loan_purpose_options = []
            }


        },

        isAmount: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
                else if(field=='monthly_salary'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='prc_id_no'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='employee_number'){
                    valueString = valueString + event.key
                    if(valueString.length > 7) return false
                }
                else if(field=='mobile_no'){
                    valueString = valueString + event.key
                    if(valueString.length > 10) return false
                }

                var str = valueString.replace(/[^0-9]/g,'')

                if(field == 'monthly_salary'){
                    var formattedString = str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    this[field] = formattedString
                } else
                    this[field] = str
                
            }
        },
      
    },
    created() {
        var year_valid_until = new Date().getFullYear() + 10
        var month = new Date().getMonth() + 1
        var day = new Date().getDate()

        var year_birthdate = new Date().getFullYear() - 18

        year_valid_until = year_valid_until.toString()
        month = month.toString()
        day = day.toString()

        // birthdate max date

        year_birthdate = year_birthdate.toString()

        this.max_date_valid_until = year_valid_until+'-'+month+'-'+day
        this.max_date_birthdate = year_birthdate+'-'+month+'-'+day


        this.fetchLookupValues('maria-loan-type').then(res => {
            this.loan_type_options = res
            this.loan_type_loading = false
        })

        this.fetchLookupValues('deped-designations').then(res => {
            this.designation_options = res
            this.designation_loading = false
        })
    },

}
</script>